import React, { useEffect, useState } from 'react';
import { useExperience } from '@unisporkal/experiences';
import { ga4Track, loginInteraction, signUpInteraction } from '@unisporkal/ga4';
import usePanelsControls from '../../../../hooks/usePanelsControls';
import useIstockLinks from '../../../../hooks/useIstockLinks';
import DesktopSeparatedButtons from './DesktopSeparatedButtons';
import EmphasizedSignInButton from './EmphasizedSignInButton';
import NavLink from '../NavLink/NavLink';
import PricingLink from '../PricingLink/PricingLink';
import AccountLink from '../AccountLink/AccountLink';
import RightPanel from '../RightPanel/RightPanel';
import styles from './SignInJoin.module.scss';

const SignInJoin = () => {
  const links = useIstockLinks();
  const [currentLocation, setCurrentLocation] = useState('');
  const iStockSignInEmphasisEnabled = useExperience(
    'iStockSignInEmphasis'
  ).isActive();
  const { showPanel, togglePanel, closePanel } = usePanelsControls(
    styles.panelOpen,
    false
  );
  const { signInAccountPanelLink, joinAccountPanelLink, yourBoards } =
    links.getSignInJoinLinks(currentLocation);

  const trackSelectLogin = (uiLocation) => {
    // GA4 tracking for select_login event
    const ga4Event = loginInteraction({
      event_name: 'select_login',
      ui_element_location: uiLocation,
    });
    ga4Track(ga4Event);
  };

  const trackSignUpStart = (uiLocation) => {
    // GA4 tracking for select_login event
    const ga4Event = signUpInteraction({
      sign_up_interaction: 'select_sign_up',
      ui_element_location: uiLocation,
    });
    ga4Track(ga4Event);
  };

  useEffect(() => {
    const returnPath = window.location.pathname + window.location.search;
    setCurrentLocation(returnPath);
  }, []);

  const navBarButtons = (screenSize) => {
    if (iStockSignInEmphasisEnabled) {
      return (
        <EmphasizedSignInButton
          currentLocation={currentLocation}
          onClick={() => trackSelectLogin('header')}
          togglePanel={togglePanel}
        />
      );
    }

    if (screenSize === 'mobile') {
      return <AccountLink togglePanel={togglePanel} />;
    }

    return (
      <DesktopSeparatedButtons
        currentLocation={currentLocation}
        onClickSignIn={() => trackSelectLogin('header')}
        onClickJoin={() => trackSignUpStart('header')}
      />
    );
  };

  return (
    <>
      <span
        className={styles.container}
        data-testid="nav-desktop"
      >
        {navBarButtons('desktop')}
      </span>
      <span className={styles.mobile}>
        {navBarButtons('mobile')}
        <RightPanel
          showPanel={showPanel}
          closePanel={closePanel}
        >
          <ul className={styles.mobileMenu}>
            <li data-testid="nav-sign_in">
              <NavLink
                dataNavLabel="SignInAccountPanel"
                link={signInAccountPanelLink}
                onClick={() => trackSelectLogin('account_panel')}
              />
            </li>
            <li data-testid="nav-join">
              <NavLink
                dataNavLabel="JoinAccountPanel"
                link={joinAccountPanelLink}
                onClick={() => trackSignUpStart('account_panel')}
              />
            </li>
            <PricingLink showDivider={false} />
            <li data-testid="nav-your_boards">
              <NavLink
                dataNavLabel="YourBoards"
                link={yourBoards}
              />
            </li>
          </ul>
        </RightPanel>
      </span>
    </>
  );
};

export default SignInJoin;
