import React from 'react';
import cx from 'classnames';
import useIstockLinks from '../../../../hooks/useIstockLinks';
import NavLink from '../NavLink/NavLink';
import styles from './EmphasizedSignInButton.module.scss';

type Props = {
  currentLocation: string;
  onClick: () => void;
  togglePanel: () => void;
};

const EmphasizedSignInButton = ({
  currentLocation,
  onClick,
  togglePanel,
}: Props) => {
  const links = useIstockLinks();
  const { signInLink } = links.getSignInJoinLinks(currentLocation);

  // For mobile, we want this to open the account panel, in desktop we want it to link to sign-in
  // If productionize we may want to separate the button and link into  separate components

  return (
    <>
      <button
        type="button"
        className={cx(
          styles.container,
          styles.emphasizedSignIn,
          styles.emphasizedSignInMobile
        )}
        onClick={togglePanel}
      >
        {signInLink.name}
      </button>
      <NavLink
        className={cx(
          styles.container,
          styles.emphasizedSignIn,
          styles.emphasizedSignInDesktop
        )}
        dataNavLabel="SignIn"
        link={signInLink}
        onClick={onClick}
      />
    </>
  );
};

export default EmphasizedSignInButton;
